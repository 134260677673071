import React, { useEffect } from "react";
import "./DashBoard.css";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_URI } from "../Utils/constants";

import Loader from "../Utils/Loader";

const DashBoard = () => {
  const [projects, setProjects] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [display, setDisplay] = React.useState("hide");
  var userBoolean;
  user.username === "admin" ? (userBoolean = true) : (userBoolean = false);
  const getProjects = async () => {
    try {
      const response = await fetch(`${SERVER_URI}/api/v1/projects/`);
      const data = await response.json();
      setProjects(data.data);

      //resource killer
      // setTimeout(() => getProjects(), 60000);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProjects();
  }, [projects.length, userBoolean]);
  // project upload
  const projectUpload = (e) => {
    e.preventDefault();
    setDisplay("show");
    const title = e.target[0].value;
    const description = e.target[1].value;
    const githubURI = e.target[2].value;
    const liveURI = e.target[3].value;
    const projectImage = e.target[4].files[0];

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("githubURI", githubURI);
    formData.append("liveURI", liveURI);
    formData.append("projectImage", projectImage);

    fetch(`${SERVER_URI}/api/v1/projects/upload-project`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
      body: formData,
    }).then((response) => {
      if (response.status === 201) {
        response.json().then((data) => {
          projects.push(data.data);
          setDisplay("hide");
          toast.success("Project uploaded successfully");
          console.log("project uploaded successfully");
        });
      } else {
        setDisplay("hide");
        toast.error("Project upload failed");
      }
    });
  };

  // verify access token

  const [projectID, setProjectID] = React.useState("");
  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken === undefined || accessToken === null) {
      window.location.href = "/secured/api/v1/admin/auth/";
    } else {
      fetch(`${SERVER_URI}/api/v1/users/get-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setUser(data.data);
            if (data.data.username === "admin") {
              getProjects();
            }
          });
        } else {
          window.location.href = "/secured/api/v1/admin/auth/";
        }
      });
    }
  }, []);
  const [edit, setEdit] = React.useState(false);

  const projectClickHandler = (id) => {
    setProjectID(id);
    console.log(id);
    if (edit) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  };
  const editProject = () => {
    projects.pop(projectID);
  };
  const deleteProject = async () => {
    console.log("project id:", projectID);

    await fetch(`${SERVER_URI}/api/v1/projects/remove-project/${projectID}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        toast.error("Project deleted successfully");
        for (var i = 0; i < projects.length; i++) {
          if (projects[i]._id === projectID) {
            projects.splice(i, 1);
          }
        }
      } else {
        window.alert("Project delete failed");
      }
    });

    console.log(projects.length);
  };
  const logout = async () => {
    await fetch(`${SERVER_URI}/api/v1/users/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("refreshToken");
        window.localStorage.removeItem("user");
        window.location.href = "/secured/api/v1/admin/auth/";
      } else {
        window.alert("Logout failed");
      }
    });
  };

  return user.username === "admin" ? (
    <div>
      <ToastContainer />
      <div className="flex">
        <h1>Dashboard</h1>
        <button className="btn btn-danger" onClick={logout}>
          logout
        </button>
      </div>
      <h2>Welcome {user.username}</h2>

      <form onSubmit={projectUpload}>
        <input type="text" placeholder="Enter project title" />
        <input type="text" placeholder="Enter project description" />
        <input type="text" placeholder="Enter github url" />
        <input type="text" placeholder="Enter live url" />

        <input type="file" placeholder="Image" typeof="image" />

        <button disabled={edit} className="btn btn-primary flex" type="submit">
          <span>Create Project </span>
          <Loader display={`${display}`} />
        </button>
      </form>
      <button
        disabled={!edit}
        onClick={() => {
          editProject();
        }}
        className="btn btn-info"
      >
        Edit Project
      </button>
      <button
        disabled={!edit}
        onClick={() => {
          deleteProject();
        }}
        className="btn btn-danger m-4"
      >
        Delete Project
      </button>
      <div>
        <div className="container portfolio__container">
          {projects.length > 0 ? (
            projects.map((project) => (
              <article
                onClick={() => {
                  projectClickHandler(project._id);
                }}
                className="portfolio__item"
                key={project._id}
                data-aos-anchor=".portfolio__container"
              >
                <div className="portfolio__item-image">
                  <img
                    src={project.projectImage}
                    alt={`${project.projectImage} app Screenshot`}
                  />
                </div>
                <h3>{project.title}</h3>
                <i>{project.description}</i>
                <div className="portfolio__item-cta mt-2">
                  <a
                    className="btn"
                    href={project.githubURI}
                    target="_blank"
                    rel="noreferrer"
                  >
                    GitHub
                  </a>
                  <a
                    className="btn btn-primary"
                    href={project.liveURI}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Live Demo
                  </a>
                </div>
              </article>
            ))
          ) : (
            <h3>No projects found</h3>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <h2>Welcome {user.username}</h2>
      <div>
        <button className="btn btn-danger" onClick={logout}>
          logout
        </button>
      </div>
    </div>
  );
};

export default DashBoard;
