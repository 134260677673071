import React from "react";
import CV from "../../assets/Shashwat_Sagar.pdf";
import "aos/dist/aos.css";

const CTA = () => {
  return (
    <div className="cta">
      <a data-aos="fade-right" href={CV} download className="btn">
        Resume
      </a>
      <a data-aos="fade-left" href="#contact" className="btn btn-primary">
        Let's Connect
      </a>
    </div>
  );
};

export default CTA;
