import React from "react";
import "./header.scss";
import CTA from "./CTA";
import Logo from "../../assets/bgrem.png";
import HeaderSocial from "./HeaderSocials";

import "aos/dist/aos.css";

const Header = () => {
  return (
    <header id="home">
      <article className="container header__container">
        <h5
          data-aos="fade-up"
          data-aos-anchor=".header__container"
          data-aos-duration="1200"
        >
          Hello I'm
        </h5>
        <h1
          data-aos="fade-up"
          data-aos-anchor=".header__container"
          data-aos-duration="1000"
        >
          Shashwat Sagar
        </h1>
        <h5
          data-aos="fade-up"
          data-aos-anchor=".header__container"
          data-aos-duration="900"
          className="text-light"
        >
          Full-stack Software Developer
        </h5>
        <CTA />

        <HeaderSocial />

        <div className="logoHeader">
          <img
            className="logo-image"
            data-aos="slide-up"
            data-aos-anchor=".logo"
            data-aos-duration="1400"
            src={Logo}
            alt="me"
          />
          <div className="logoTop">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-25 -25 250 250"
              className="float"
            >
              <defs>
                <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
                  <stop
                    offset="0%"
                    style={{ stopColor: "#7AFF2D", stopOpacity: 1.0 }}
                  />
                  <stop
                    offset="100%"
                    style={{ stopColor: "#007aff", stopOpacity: 1.0 }}
                  />
                </linearGradient>
              </defs>

              <path
                d="M194.24620022273518 66.56867122628672 C189.2577706690393 53.14573420782181 171.1847421616693 29.794639915544924 160.4381392385624 20.330486851116362 C155.039138228807 15.57577763584768 142.16518977592222 8.394857869618708 135.24791852461374 6.418034645118766 C120.66909576167126 2.2516871973153334 89.49798334202366 0.043010111345225255 74.68038805799057 3.258502951907687 C63.84934426093453 5.608894032083866 42.687642411481654 15.406680875629226 35.34746203618701 23.710752167571343 C19.14303742450447 42.04309420009419 0.802762288239049 90.96509365263212 1.197502094241159 115.42940075254126 C1.3843752851245972 127.0110123888598 11.858015951598198 149.00102273235038 18.756808961007025 158.30560787610136 C26.55105600756394 168.81791563445998 47.23366400672238 186.40453930520226 59.34463553591436 191.36269118350424 C74.12061971016985 197.41188166428088 107.26593432937952 200.4280720004385 122.9298765475201 197.33560890812512 C135.16757676735028 194.91957367653495 158.1796094302669 182.8530403023976 167.10618797228346 174.14013444706285 C178.61380408676956 162.90797249913243 194.96855202683497 133.64647502529687 198.37913723452982 117.93168583791132 C201.11137037947063 105.34250677659935 198.73381587838367 78.64401110238411 194.24620022273518 66.56867122628672Z"
                stroke="none"
                fill="url(#lgrad)"
              />
            </svg> */}
          </div>
        </div>

        <a href="#contact" className="scroll__down">
          Scroll Down {">>"}{" "}
        </a>
      </article>
    </header>
  );
};

export default Header;
