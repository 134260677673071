import React from "react";
import "./portfolio.css";
import { SERVER_URI } from "../Utils/constants";

const Portfolio = () => {
  /* const projects = [
     {
      name: "LocalShopIndia Bot",
      deployedLink: "https://lsi-bot.netlify.app/",
      image: require("../../assets/bot.png"),
      fadeDuration: "1100",
      repo: "LSI_bot",
    },
    {
      name: "Athlete Club",
      deployedLink: "https://athlete-club-mobile.netlify.app/",
      image: require("../../assets/athlete.png"),
      fadeDuration: "1000",
      repo: "AthleteMobile",
    },
    {
      name: "Metaverse",
      deployedLink: "https://metaverse-shashwat.netlify.app/",
      image: require("../../assets/metaverse.png"),
      fadeDuration: "1100",
      repo: "Metaverse_concept",
    },
    {
      name: "House Cleaners",
      deployedLink: "https://cleaning-expert.netlify.app/",
      image: require("../../assets/cleaning.png"),
      fadeDuration: "1100",
      repo: "cleaning-webapp",
    },
     {
      name: "Instant Pizza",
      deployedLink: "https://instant-pizza.netlify.app/",
      image: require("../../assets/pizza.png"),
      fadeDuration: "1100",
      repo: "Pizza_Box",
    },
    {
      name: "Client Portfolio",
      deployedLink: "https://diya-biswas.netlify.app/",
      image: require("../../assets/client.png"),
      fadeDuration: "1100",
    },
     {
      name: "Chatify",
      deployedLink: "https://chatify-web.netlify.app/",
      image: require("../../assets/chatify.png"),
      fadeDuration: "1100",
      repo: "chatify",
    },
    {
      name: "Text Converter with 11 Themes",
      deployedLink: "https://text-converter-shashwat.netlify.app/",
      image: require("../../assets/textconverter.png"),
      fadeDuration: "1100",
      repo: "Text-Converter",
    },
    {
      name: "Job Portal (Vue.js)",
      deployedLink: "https://jobportal-vue.netlify.app",
      image: require("../../assets/job.png"),
      fadeDuration: "1100",
      repo: "Job-Portal",
    },
   
    {
      name: "News Monkey",
      deployedLink: "/",
      image: require("../../assets/news.png"),
      fadeDuration: "1100",
      repo:"newsmonkey",
    },
     {
      name: "Todo App (Vue.js)",
      deployedLink: "https://todo-list0-vue.netlify.app/",
      image: require("../../assets/todo.png"),
      fadeDuration: "1100",
      repo:"Todo-List-App",
    },
    {
      name: "Material Calculator",
      deployedLink: "https://calculator-project-badass1901.netlify.app/",
      image: require("../../assets/calc.png"),
      fadeDuration: "1100",
      repo:"Calculator-Project",
    },
  ];
  */
  const [projects, setProjects] = React.useState([]);
  React.useEffect(() => {
    try {
      //resource killer

      fetch(`${SERVER_URI}/api/v1/projects/`).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setProjects(data.data);
          });
        } else {
          console.log("error");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <section id="portfolio">
      <h5
        data-aos="fade-up"
        data-aos-anchor=".portfolio__container"
        data-aos-duration="700"
      >
        My most recent projects
      </h5>
      <h2
        data-aos="fade-up"
        data-aos-anchor=".portfolio__container"
        data-aos-duration="900"
      >
        My Portfolio
      </h2>

      <div className="container portfolio__container">
        {projects.length > 0 ? (
          projects.map((project, fadeDuration = 1100) => (
            <article
              className="portfolio__item"
              key={project._id}
              data-aos="fade-up"
              data-aos-anchor=".portfolio__container"
              data-aos-duration={fadeDuration}
            >
              <div className="portfolio__item-image">
                <img
                  src={project.projectImage}
                  alt={`${project.projectImage} app Screenshot`}
                />
              </div>
              <h3>{project.title}</h3>
              <i>{project.description}</i>
              <div className="portfolio__item-cta mt-2">
                <a
                  className="btn"
                  href={project.githubURI}
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>
                <a
                  className="btn btn-primary"
                  href={project.liveURI}
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
              </div>
            </article>
          ))
        ) : (
          <h3>loading...</h3>
        )}
      </div>
    </section>
  );
};

export default Portfolio;
