import React, { useEffect } from "react";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_URI } from "../Utils/constants";

const Login = () => {
  const userAccessToken = window.localStorage.getItem("accessToken");
  const userRefreshToken = window.localStorage.getItem("refreshToken");
  useEffect(() => {
    checkUser();
  });

  const verifyToken = async (userAccessToken, userRefreshToken) => {
    await fetch(`${SERVER_URI}/api/v1/users/get-user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userAccessToken}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          window.localStorage.setItem("user", JSON.stringify(data.data));
          window.location.href = "/secured/api/v1/admin";
        });
      } else if (userRefreshToken !== undefined) {
        refreshToken(window.localStorage.getItem("refreshToken"));
      } else {
        toast.error("please login again");
      }
    });
  };

  function refreshToken(userRefreshToken) {
    toast.info("Refreshing token");
    fetch(`${SERVER_URI}/api/v1/users/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken: userRefreshToken }),
    }).then((response) => {
      if (response.status === 200) {
        toast.success("Token refreshed successfully");
        response
          .json()
          .then((data) => {
            console.log(data);
            window.localStorage.setItem("accessToken", data.data.accessToken);
          })
          .then((window.location.href = "/secured/api/v1/admin"));
      } else {
        toast.error(response.message);
        // window.location.href = "/secured/api/v1/admin/auth/";
        console.log("login again");
      }
    });
  }
  const checkUser = async () => {
    verifyToken(userAccessToken, userRefreshToken);
  };
  const login = async (e) => {
    e.preventDefault();
    // get form data
    const username = e.target[0].value;
    const password = e.target[1].value;
    // send form data to server

    try {
      await fetch(`${SERVER_URI}/api/v1/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      }).then((res) => {
        res.json().then((data) => {
          if (res.status === 200) {
            toast.success(data.message);
            window.localStorage.setItem("refreshToken", data.data.refreshToken);
            window.localStorage.setItem("accessToken", data.data.accessToken);
            const user = data.data.user;
            window.localStorage.setItem("user", JSON.stringify(user));
            setTimeout(() => {
              window.location.href = "/secured/api/v1/admin";
            }, 1000);
          } else {
            toast.error(data.message);
          }
        });
      });
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <div className="login">
      <ToastContainer />
      <h1>Login</h1>
      <form onSubmit={login}>
        <input type="text" placeholder="Username" />
        <input type="password" placeholder="Password" />
        <button className=" btn btn-primary">Submit</button>
      </form>
    </div>
  );
};

export default Login;
