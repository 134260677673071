import React, { useEffect, useState } from "react";
import All from "./All";
import AOS from "aos";
import Loader from "./components/Loader/Loader";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import DashBoard from "./components/DashBoard/DashBoard";
const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const isLoading = false
  const [activeNav, setActiveNav] = useState("#");

  useEffect(() => {
    //Stop the loader afer 2 secs
    setTimeout(() => setIsLoading(false), 2000);

    //Initialize Animate on scroll
    AOS.init({
      duration: 800,
      disable: function () {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
      },
    });

    AOS.refresh();

    let section = document.querySelectorAll("section");

    window.addEventListener("scroll", (e) => {
      if (window.scrollY < 50) {
        setActiveNav(`#`);
      }

      section.forEach((sec) => {
        let top = window.scrollY;
        let offset = sec.offsetTop - 150;
        let height = sec.offsetHeight;
        let id = sec.getAttribute("id");

        if (top >= offset && top < offset + height) {
          setActiveNav(`#${id}`);
        }
      });
    });
  }, [activeNav, isLoading]);

  return isLoading ? (
    <Loader />
  ) : (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<All setActiveNav={setActiveNav} activeNav={activeNav} />}
        />
        <Route path="/secured/api/v1/admin/auth/" element={<Login />} />
        <Route path="/secured/api/v1/admin/" element={<DashBoard />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </Router>
  );
};

export default App;
