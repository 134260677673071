import React from "react";
import {
  Header,
  Nav,
  About,
  Experience,
  Services,
  Portfolio,
  Review,
  Contact,
  Footer,
} from "./components";

const All = ({ activeNav, setActiveNav }) => {
  return (
    <>
      {/* Header section  */}
      <Header />

      {/* Nav section  */}
      <Nav activeNav={activeNav} setActiveNav={setActiveNav} />

      {/* About Section */}
      <About />

      {/* Experience section */}
      <Experience />

      {/* Services section  */}
      <Services />

      {/* Portfolio section  */}
      <Portfolio />

      {/* References section  */}
      <Review />

      {/* Contact section  */}
      <Contact />

      {/* Footer Section  */}
      <Footer />
    </>
  );
};

export default All;
